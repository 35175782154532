<template>
  <div>
    <v-card>
      <v-card-title
        ><div
          style="font-size: 16px; cursor: pointer"
          class="mr-10"
          @click="closeModal"
        >
          <v-icon @click="closeModal" small>mdi-arrow-left</v-icon
          >{{ "button_back" | localize }}
        </div>
        <div v-if="!editReview">{{ "home_add_review_label" | localize }}</div>
        <div v-if="editReview">
          {{ "home_edit_review_label" | localize }}
        </div></v-card-title
      >
      <v-card-text>
        <v-text-field
          :label="`home_review_customer_name_placeholder` | localize"
          v-model="review.title_en"
          :error-messages="title_en_error | localize"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          :label="`home_review_customer_name_placeholder_es` | localize"
          v-model="review.title_es"
          :error-messages="title_es_error | localize"
          outlined
          dense
        ></v-text-field>
        <v-textarea
          :label="`home_review_text_placeholder_en` | localize"
          counter="180"
          auto-grow
          rows="3"
          :error-messages="description_en_error | localize"
          v-model="review.description_en"
          outlined
          dense
        ></v-textarea>
        <v-textarea
          :label="`home_review_text_placeholder_es` | localize"
          counter="180"
          auto-grow
          rows="3"
          :error-messages="description_es_error | localize"
          v-model="review.description_es"
          outlined
          dense
        ></v-textarea>
        <div style="text-align: center; width: 100%">
          {{ `home_review_mark_label` | localize }}
          <v-rating
            color="#FFC909"
            hover
            length="5"
            size="40"
            v-model="review.stars"
            dense
          >
          </v-rating>
          <span style="color: #ff5252; font-size: 12px">
            {{ stars_error[0] | localize }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="end">
          <v-btn
            class="mr-2"
            dark
            text
            color="#7A7A7A"
            @click="$emit('close_modal')"
            >{{ "button_cancel" | localize }}</v-btn
          >
          <v-btn
            class="mr-2"
            dark
            color="#E62076"
            v-if="!editReview"
            @click="saveReview()"
            >{{ "button_save" | localize }}</v-btn
          >
          <v-btn
            class="mr-2"
            dark
            color="#E62076"
            v-if="editReview"
            @click="updateReview()"
            >{{ "button_update" | localize }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import reviewsService from "@/requests/Reviews/ReviewsService.js";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    index: {
      require: true,
    },
    editReview: {
      require: false,
    },
  },
  data: () => ({
    addModal: true,
    review: {},
  }),
  validations: {
    review: {
      title_en: {
        required,
      },
      title_es: {
        required,
      },
      description_en: {
        required,
        maxLength: maxLength(180),
      },
      description_es: {
        required,
        maxLength: maxLength(180),
      },
      stars: {
        required,
      },
    },
  },
  methods: {
    getReview() {
      this.$set(this.review, "title_en", this.editReview.title_en);
      this.$set(this.review, "title_es", this.editReview.title_es);
      this.$set(this.review, "description_en", this.editReview.description_en);
      this.$set(this.review, "description_es", this.editReview.description_es);
      this.$set(this.review, "stars", this.editReview.stars);
      this.review.position = this.editReview.position;
      this.review.id = this.editReview.id;
    },
    closeModal() {
      this.addModal = false;
      this.$emit("close_modal");
    },
    async updateReview() {
      this.$v.review.$touch();

      if (!this.$v.review.$invalid) {
        let response = await reviewsService.updateItem(this.review.id, {
          ...this.review,
        });
        if (response.status == "success") {
          this.addModal = false;
          this.$emit("added");
        }
      }
    },
    async saveReview() {
      this.$v.review.$touch();

      if (!this.$v.review.$invalid) {
        let response = await reviewsService.addNewItem({
          ...this.review,
          position: this.index > 0 ? this.index : 0,
        });
        if (response.status == "success") {
          this.addModal = false;
          this.$emit("added");
        }
      }
    },
  },
  mounted() {
    if (this.editReview) {
      this.getReview();
    }
  },
  watch: {
    addModal: {
      handler() {
        this.$emit("close_modal");
      },
    },
  },
  computed: {
    title_en_error() {
      const errors = [];
      if (!this.$v.review.title_en.$dirty) {
        return errors;
      }
      !this.$v.review.title_en.required &&
        errors.push("home_customer_name_validation");
      return errors;
    },
    title_es_error() {
      const errors = [];
      if (!this.$v.review.title_es.$dirty) {
        return errors;
      }
      !this.$v.review.title_es.required &&
        errors.push("home_customer_name_validation");
      return errors;
    },
    description_en_error() {
      const errors = [];
      if (!this.$v.review.description_en.$dirty) {
        return errors;
      }
      !this.$v.review.description_en.required &&
        errors.push("home_review_text_validation");
      if (!this.$v.review.description_en.maxLength) {
        errors.push("home_review_text_length_validation");
      }
      return errors;
    },
    description_es_error() {
      const errors = [];
      if (!this.$v.review.description_es.$dirty) {
        return errors;
      }
      !this.$v.review.description_es.required &&
        errors.push("home_review_text_validation");
      if (!this.$v.review.description_es.maxLength) {
        errors.push("home_review_text_length_validation");
      }
      return errors;
    },
    stars_error() {
      const errors = [];
      if (!this.$v.review.stars.$dirty) {
        return errors;
      }
      !this.$v.review.stars.required && errors.push("home_mark_validation");
      return errors;
    },
  },
};
</script>