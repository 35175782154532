<template>
  <div>
    <v-dialog v-model="editModal" max-width="1000px" persistent>
      <v-card class="pa-3" style="position: relative" v-if="!addModal">
        <v-card-title
          >{{ "home_edit_reviews" | localize }}
          <v-btn
            fab
            color="#E62076"
            right
            dark
            small
            center
            absolute
            class="mb-1"
            @click="openAddModal()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn></v-card-title
        >

        <!-- <v-row v-if="reviews.length > 0 && !loading" class="pa-5"> -->
          <draggable v-model="reviews" class="row wrap pa-5" v-if="reviews.length > 0 && !loading">
            <v-col
              class="col-lg-4 col-md-6 col-sm-12"
              v-for="review in reviews"
              :key="review.id"
            >
              <v-card class="reviews_card px-4 py-5">
                <v-icon
                  style="position: absolute; right: 10px; top: 10px"
                  @click="editItem(review)"
                  color="#E62076"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  style="position: absolute; right: 35px; top: 10px"
                  @click="deleteItem(review.id)"
                  color="#EB5757"
                  class="ml-2"
                  >mdi-delete</v-icon
                >
                <v-rating
                  color="#FFC909"
                  hover
                  length="5"
                  readonly
                  size="13"
                  :value="parseInt(review.stars)"
                  dense
                >
                </v-rating>
                <div class="text_review mt-2" v-if="loggedUser">
                  {{
                    loggedUser.locale == "en"
                      ? review.description_en
                      : review.description_es
                  }}
                </div>
                <div class="text_review mt-2" v-else>
                  {{
                    user.locale == "en"
                      ? review.description_en
                      : review.description_es
                  }}
                </div>
                <div class="author_review mt-4" v-if="loggedUser">
                  {{
                    loggedUser.locale == "en"
                      ? review.title_en
                      : review.title_es
                  }}
                </div>
                <div class="author_review mt-4" v-else>
                  {{ user.locale == "en" ? review.title_en : review.title_es }}
                </div>
              </v-card>
            </v-col>
          </draggable>
        <!-- </v-row> -->
        <div
          v-if="reviews.length === 0 && !loading"
          style="min-height: 200px"
          class="d-flex align-center justify-center"
        >
          {{ 'home_no_reviews_label' | localize }}
        </div>
        <div
          v-if="loading"
          class="d-flex align-center"
          style="min-height: 300px"
        >
          <v-progress-circular
            v-if="loading"
            class="mx-auto"
            indeterminate
            color="#E62076"
          ></v-progress-circular>
        </div>
        <v-row class="mr-3">
           <v-spacer />
          <v-btn class="mb-4" text color="#7A7A7A" @click="editModal = false">
            {{ "button_cancel" | localize }}
          </v-btn>
        </v-row>

      </v-card>
      <add-review-modal
        v-if="addModal"
        :editReview="editReview"
        @close_modal="closeAddModal"
        @added="addedReview"
        :index="reviews.length"
      />
    </v-dialog>
  </div>
</template>

<script>
import addReviewModal from "./addReviewModal.vue";
import reviewsService from "@/requests/Reviews/ReviewsService.js";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  components: {
    addReviewModal,
    draggable,
  },
  data: () => ({
    editModal: true,
    addModal: false,
    reviews: [],
    loading: false,
    newList: true,
    editReview: null,
  }),
  mounted() {
    this.getReviews();
  },
  methods: {
    editItem(review) {
      this.editReview = review;
      this.addModal = true;
    },
    addedReview() {
      this.addModal = false;
      this.editReview = null;
      this.getReviews();
      this.$emit("changed");
    },
    closeAddModal() {
      this.addModal = false;
      this.editReview = null;
    },
    openAddModal() {
      this.addModal = true;
    },
    closeModal() {
      this.editModal = false;
      this.$emit("close_modal");
    },
    async deleteItem(id) {
      await reviewsService.deleteItem(id);
      this.getReviews();
      this.$emit("changed");
    },
    async getReviews() {
      this.loading = true;
      await reviewsService.getReviewsList().then((response) => {
        this.reviews = response.result.sort((a, b) => a.position - b.position);
        this.loading = false;
      });
    },
    updateList() {
      this.reviews.forEach((item, index) =>
        reviewsService.updateItem(item.id, {
          title_en: item.title_en,
          title_es: item.title_es,
          description_en: item.description_en,
          desctiption_es: item.description_es,
          position: index,
          stars: item.stars,
        })
      );
    },
  },
  watch: {
    editModal: {
      handler() {
        this.$emit("changed");
        this.$emit("close_modal");
      },
    },
    reviews: {
      deep: true,
      handler() {
        this.updateList();
        this.$emit("changed");
      },
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style scoped>
.reviews_card {
  background: rgba(230, 32, 118, 0.03);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  text-align: center;
}

.text_review {
  font-weight: 500;
  font-size: 12px;
  line-height: 130.4%;
}

.author_review {
  font-weight: 600;
  font-size: 12px;
  line-height: 130.4%;
}
</style>