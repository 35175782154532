<template>
  <v-container>
    <v-row align="center" class="my-5" no-gutters>
      <div style="font-weight: 700; font-size: 28px; line-height: 34px">
        {{ "home_title_attractions" | localize }}
      </div>
      <v-divider class="mt-5 ml-3" />
    </v-row>
    <div v-if="loading" class="d-flex align-center" style="min-height: 600px">
      <v-progress-circular
        v-if="loading"
        class="mx-auto"
        indeterminate
        color="#E62076"
      ></v-progress-circular>
    </div>
    <v-row no-gutters v-if="!loading">
        <v-col
          class="mb-5 col-12 col-sm-6 col-lg-3"
          v-for="(attraction, index) in attractions"
          :key="index"
        >
          <v-card
            elevation="0"
            :class="
              attraction.status == 'Inactive'
                ? 'px-3 attraction-card__inner text-center disabled'
                : 'px-3 attraction-card__inner text-center'
            "
          >
            <router-link
              :to="
                !isMobile
                  ? '/attractions/' + attraction.uid + '?isMain=true'
                  : '/mobile/attractions/' + attraction.uid + '?isMain=true'
              "
              style="text-decoration: none"
            >
              <v-row
                align="center"
                justify="center"
                v-for="(image, i) in attraction.images"
                :key="image.id"
                no-gutters
              >
                <v-img
                  style="border-radius: 10%"
                  class="mt-5"
                  max-width="220px"
                  height="220px"
                  v-if="i == 0"
                  :src="image.thumbs[2]"
                  alt="no img"
                />
              </v-row>
              <div class="attraction-card-title mb-md-1 mt-md-6 mt-1 mb-1">
                {{ attraction.title }}
              </div>
              <div class="attraction-card-subtitle">
                {{ attraction.short_description
                }}
                <!-- <span v-if="attraction.short_description.length > 80"
                  >...</span> -->
              </div>
            </router-link>
          </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>


<script>
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import { mapGetters } from "vuex";
import AttractionsService from "@/requests/Attractions/AttractionsService.js";
import mobile from "@/mixins/mobileMixin";
export default {
  components: {
  },
  mixins: [mobile],
  data: () => ({
    attractions: [],
    loading: true,
    admin: false,
    disableDraggable: true,
    newAttractionList: [],
    timeout: 1000,
  }),
  mounted() {
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
      this.disableDraggable = false;
    }
    this.getAttractions();
  },
  methods: {
    async getAttractions() {
      await AttractionService.getAttractions().then((res) => {
        this.attractions = res.result;
        this.loading = false;
      });
      if (!this.admin) {
        setTimeout(() => {
          this.attractions = this.attractions.filter(
            (attr) => attr.status == "Active"
          );
        }, 100);
      }
    },
    async updateAttractionList() {
      await AttractionsService.updateAttractionOrder({
        order: [...this.newAttractionList],
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
          this.disableDraggable = false;
        } else {
          this.admin = false;
        }
        if (!this.admin) {
          this.attractions = this.attractions.filter(
            (attr) => attr.status == "Active"
          );
        }
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.loading = true;
        this.getAttractions();
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.loading = true;
        this.getAttractions();
      },
    },
    attractions: {
      deep: true,
      handler() {
        this.newAttractionList = [];
        this.attractions.forEach((attraction, index) => {
          this.newAttractionList.push({ uid: attraction.uid, order: index });
        });
      },
    },
    newAttractionList: {
      deep: true,
      handler() {
        if (this.admin) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.updateAttractionList();
          }, 1000);
        }
      },
    },
  },
};
</script>
<style scoped>
.attraction-card__inner img {
  border-radius: 21px !important;
}

.attraction-card-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.attraction-card-subtitle {
  font-weight: 500;
  font-size: 12.6px;
  line-height: 16px;

  color: #212121;
}

.disabled {
  opacity: 0.2;
}
</style>