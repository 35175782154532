<template>
  <div>
    <v-container>
      <div style="position: relative">
        <v-row align="center" class="px-3 mt-4 mb-10">
          <div @click="toAttractions" style="cursor: pointer; font-weight: 700; font-size: 28px; line-height: 34px">
            {{ "home_public_transport_label" | localize }}
          </div>
          <v-divider class="mt-5 ml-3" />
        </v-row>
        <!-- <v-btn
          fab
          color="#E62076"
          right
          dark
          small
          v-if="admin"
          top
          absolute
          style="margin-top: 25px"
          @click="isEdit = true"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
      </div>
      <div
        v-if="!isEdit && loading"
        class="d-flex align-center"
        style="min-height: 600px"
      >
        <v-progress-circular
          v-if="loading"
          class="mx-auto"
          indeterminate
          color="#E62076"
        ></v-progress-circular>
      </div>
      <div v-if="!isEdit && !loading">
        <v-row justify="center">
          <v-col
            class="col-6 col-sm-6 col-md-3 text-center"
            v-for="(image, i) in attraction.images"
            :key="i"
          >
            <v-img
              @click="toAttractions"
              style="border-radius: 20px; width: 100%; cursor: pointer;"
              :src="image.thumbs[1]"
            />
          </v-col>
        </v-row>

        <v-row no-gutters style="width: 100% !important" class="mt-2">
          <v-col class="col-12">
            <div class="descr" v-html="attraction.description"></div>
          </v-col>
        </v-row>
      </div>
      <!-- <v-col class="px-0 py-0" v-else>
        <div>
          <ckeditor
            :editor="editor"
            v-model="publicTransportText"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <v-row no-gutters justify="end" class="mt-5">
          <v-btn text @click="isEdit = false" class="mr-5">{{
            "button_cancel" | localize
          }}</v-btn>
          <v-btn color="#E62076" dark @click="savePublicTransportText">{{
            "button_update" | localize
          }}</v-btn>
        </v-row>
      </v-col> -->
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Editor from "../../../ckeditor5/build/ckeditor";
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import cities from "@/mixins/cityMixin";
import serverMixin from "@/mixins/serverMixin"
import userSettingsMixin from '@/mixins/userSettingsMixin'

export default {
  data: () => ({
    // editor: Editor,
    // editorConfig: {},
    publicTransportText: "",
    isEdit: false,
    attraction: false,
    loading: false,
  }),
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  mixins: [cities, serverMixin, userSettingsMixin],
  mounted() {
    this.getAttraction();
  },
  watch: {
    locale: {
      handler() {
        this.getAttraction()
      }
    }
  },
  methods: {
    toAttractions() {
      this.$router.push('/attractions/' + this.additionalUuid + '?isMain=true')
    },
    async getAttraction() {
      this.loading = true;

      if (!this.mazatlan) {
        await AttractionService.getAttraction(
          this.additionalUuid
        ).then((res) => {
          this.attraction = res.result;
          this.loading = false;
        });
      } else {
        this.attraction = {};
        this.loading = false;
      }
    },
    savePublicTransportText() {
      this.isEdit = false;
    },
  },
};
</script>

<style scoped>
.descr {
  width: 100% !important;
  text-align: justify;
}

.descr p {
  text-align: justify;
}
</style>