<template>
  <v-dialog v-model="visibility" width="400px">
    <v-card>
      <v-card-title style="word-break: break-word">{{
        "home_banner_delete_image_popup" | localize
      }}</v-card-title>
      <v-card-actions>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn class="mb-4" text color="#7A7A7A" @click="visibility = false">
            {{ "button_no" | localize }}
          </v-btn>
          <v-btn dark color="#e62076" @click="$emit('onConfirm')">{{
            "button_yes" | localize
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      require: true,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>