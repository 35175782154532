import store from '@/store';
import requestService from '../requestService';

export default {
    async getReviewsList() {
        const response = await requestService.get(`reviews`, {
            headers: {
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async addNewItem(form) {
        const response = await requestService.post(`reviews`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async deleteItem(id) {
        const response = await requestService.delete(`reviews/${id}`, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async getItem(id) {
        const response = await requestService.get(`reviews/${id}`)
        return response?.data
    },
    async updateItem(id, form) {
        const response = await requestService.put(`reviews/${id}`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
}