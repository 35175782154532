<template>
  <home-page />
</template>

<script>
import HomePage from '@/components/Home/HomeComponent.vue'
export default {
    components: {
        HomePage
    }
}
</script>