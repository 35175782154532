<template>
  <v-dialog v-model="editModal" width="600px">
    <v-card color="white" class="black--text">
      <v-card-title>{{ "home_edit_text_label" | localize }}</v-card-title>
      <v-card-text v-if="!title">
        <v-textarea
          outlined
          dense
          rows="3"
          :label="'home_text_label_en' | localize"
          :counter="counter"
          :error-messages="text_en_error | localize"
          v-model="text.en"
        >
        </v-textarea>
        <v-textarea
          outlined
          dense
          rows="3"
          :label="'home_text_label_es' | localize"
          :counter="counter"
          :error-messages="text_es_error | localize"
          v-model="text.es"
        >
        </v-textarea>
      </v-card-text>
      <v-card-text v-if="title">
        <v-text-field
          outlined
          dense
          :label="'home_title_en' | localize"
          v-model="title_text.en"
          :error-messages="title_text_en_error | localize"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          :label="'home_title_es' | localize"
          v-model="title_text.es"
          :error-messages="title_text_es_error | localize"
        ></v-text-field>
        <v-textarea
          outlined
          dense
          rows="3"
          :label="'home_text_label_en' | localize"
          v-model="text.en"
          :error-messages="text_en_error | localize"
        >
        </v-textarea>
        <v-textarea
          outlined
          dense
          rows="3"
          :label="'home_text_label_es' | localize"
          v-model="text.es"
          :error-messages="text_es_error | localize"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-spacer />
          <v-btn class="mb-4" text color="#7A7A7A" @click="editModal = false">
            {{ "button_cancel" | localize }}
          </v-btn>
          <v-btn dark color="#e62076" @click="saveText()" v-if="!title">
            {{ "button_save" | localize }}
          </v-btn>
          <v-btn dark color="#e62076" @click="saveTitle()" v-if="title">
            {{ "button_save" | localize }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import languageService from "@/requests/Language/languageService.js";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [validationMixin, cityMixin],
  props: {
    rules: {
      require: true,
    },
    code: {
      require: false,
    },
  },
  data: () => ({
    editModal: true,
    text: {
      en: "",
      es: "",
    },
    title_text: {
      en: "",
      es: "",
    },
    title: false,
    en: en,
    es: es,
    counter: 160,
  }),
  validations() {
    return {
      text: {
        en: {
          required,
          maxLength: maxLength(this.counter),
        },
        es: {
          required,
          maxLength: maxLength(this.counter),
        },
      },
      title_text: {
        en: {
          required,
        },
        es: {
          required,
        },
      },
    };
  },
  mounted() {
    if (this.rules == "title") {
      this.title = true;
      this.code = `manual${this.mazatlan ? "_mzt" : ""}_home_page_banner_text`;
      this.$set(
        this.title_text,
        "en",
        this.en[`manual${this.mazatlan ? "_mzt" : ""}_home_page_banner_title`]
      );
      this.$set(
        this.title_text,
        "es",
        this.es[`manual${this.mazatlan ? "_mzt" : ""}_home_page_banner_title`]
      );
    }
    if (this.rules == "description") {
      this.counter = 750;
    }
    this.$set(this.text, "en", this.en[this.code]);
    this.$set(this.text, "es", this.es[this.code]);
  },
  watch: {
    editModal: {
      handler() {
        this.editModal = false;
        this.$emit("close_modal");
      },
    },
  },
  methods: {
    async saveText() {
      this.$v.text.$touch();

      if (!this.$v.text.$invalid) {
        let data = {
          es: this.text.es,
          en: this.text.en,
        };
        console.log(data);
        await languageService
          .updateLanguage(this.code, data)
          .then(() => {
            this.editModal = false;
          })
          .catch((e) => {
            alert(e);
          });
      }
    },
    async saveTitle() {
      this.$v.text.$touch();
      this.$v.title_text.$touch();

      if (!this.$v.text.$invalid && !this.$v.title_text.$invalid) {
        let data = {
          es: this.text.es,
          en: this.text.en,
        };
        await languageService
          .updateLanguage(
            `manual${this.mazatlan ? "_mzt" : ""}_home_page_banner_text`,
            data
          )
          .then(() => {
            let title = {
              es: this.title_text.es,
              en: this.title_text.en,
            };
            languageService
              .updateLanguage(
                `manual${this.mazatlan ? "_mzt" : ""}_home_page_banner_title`,
                title
              )
              .then(() => {
                this.editModal = false;
              });
          });
      }
    },
  },
  computed: {
    text_en_error() {
      const errors = [];
      if (!this.$v.text.en.$dirty) {
        return errors;
      }
      !this.$v.text.en.required && errors.push("home_text_en_required");
      if (!this.$v.text.en.maxLength) {
        errors.push("The maximum number of characters is 160");
      }
      return errors;
    },
    text_es_error() {
      const errors = [];
      if (!this.$v.text.es.$dirty) {
        return errors;
      }
      !this.$v.text.es.required && errors.push("home_text_es_required");
      if (!this.$v.text.es.maxLength) {
        errors.push("The maximum number of characters is 160");
      }
      return errors;
    },
    title_text_en_error() {
      const errors = [];
      if (!this.$v.title_text.en.$dirty) {
        return errors;
      }
      !this.$v.title_text.en.required && errors.push("home_title_en_required");
      return errors;
    },
    title_text_es_error() {
      const errors = [];
      if (!this.$v.title_text.es.$dirty) {
        return errors;
      }
      !this.$v.title_text.es.required && errors.push("home_title_en_required");
      return errors;
    },
  },
};
</script>
