<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <div style="width: 100%">
          <div
            class="main"
            style="position: relative; width: 100%; height: 500px"
          >
            <transition name="slide-fade">
              <!-- 			SRC comes from the array of images the :key is important for vue to believe its a 'new' DOM element and do the transition -->
              <img
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                "
                v-bind:src="images[currentID]"
                v-bind:key="currentID"
              />
            </transition>

            <v-row no-gutters style="position: relative; z-index: 2">
              <v-btn
                absolute
                small
                right
                fab
                color="#fff"
                dark
                v-if="admin"
                style="margin-top: 20px"
                @click="editText('title', '')"
              >
                <v-icon color="error">mdi-pencil</v-icon>
              </v-btn>
              <!-- <v-btn
                absolute
                small
                right
                fab
                color="#fff"
                dark
                v-if="admin"
                style="margin-top: 20px"
                @click="galleryDialogVisible = true"
              >
                <v-icon color="error">mdi-camera</v-icon>
              </v-btn> -->
              <div class="ml-0 ml-sm-10 mt-sm-15 mt-7 title__inner">
                <v-col class="col-sm-8 col-12">
                  {{
                    `manual${mazatlan ? "_mzt" : ""}_home_page_banner_title`
                      | localize
                  }}
                </v-col>
              </div>
            </v-row>
            <v-row no-gutters>
              <div class="ml-0 ml-sm-10 mt-sm-4 mt-1 subtitle__inner">
                <v-col class="col-sm-7 col-12 py-sm-auto py-0">{{
                  `manual${mazatlan ? "_mzt" : ""}_home_page_banner_text`
                    | localize
                }}</v-col>
              </div>
            </v-row>
          </div>
        </div>
        <div style="margin-top: -35px; width: 100%" class="mx-md-6 mx-0">
          <v-row>
            <v-col class="col-lg-3 col-6">
              <v-card elevation="0" class="pa-5 pb-9 card__steps">
                <v-btn
                  icon
                  absolute
                  right
                  v-if="admin"
                  style="margin-top: -15px"
                  @click="
                    editText(
                      'text',
                      `manual${mazatlan ? '_mzt' : ''}_home_page_banner_1`
                    )
                  "
                >
                  <v-icon color="error">mdi-pencil</v-icon>
                </v-btn>
                <img src="@/assets/1.svg" style="position: absolute" />
                <div class="mt-3 card__text">
                  {{
                    `manual${mazatlan ? "_mzt" : ""}_home_page_banner_1`
                      | localize
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col class="col-lg-3 col-6">
              <v-card elevation="0" class="pa-5 pb-9 card__steps">
                <v-btn
                  icon
                  absolute
                  right
                  v-if="admin"
                  style="margin-top: -15px"
                  @click="
                    editText(
                      'text',
                      `manual${mazatlan ? '_mzt' : ''}_home_page_banner_2`
                    )
                  "
                >
                  <v-icon color="error">mdi-pencil</v-icon>
                </v-btn>
                <img src="@/assets/2.svg" style="position: absolute" />
                <div class="mt-3 card__text">
                  {{
                    `manual${mazatlan ? "_mzt" : ""}_home_page_banner_2`
                      | localize
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col class="col-lg-3 col-6">
              <v-card elevation="0" class="pa-5 pb-9 card__steps">
                <v-btn
                  icon
                  absolute
                  right
                  v-if="admin"
                  style="margin-top: -15px"
                  @click="
                    editText(
                      'text',
                      `manual${mazatlan ? '_mzt' : ''}_home_page_banner_3`
                    )
                  "
                >
                  <v-icon color="error">mdi-pencil</v-icon>
                </v-btn>
                <img src="@/assets/3.svg" style="position: absolute" />
                <div class="mt-3 card__text">
                  {{
                    `manual${mazatlan ? "_mzt" : ""}_home_page_banner_3`
                      | localize
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col class="col-lg-3 col-6">
              <v-card elevation="0" class="pa-5 pb-8 card__steps">
                <v-btn
                  icon
                  absolute
                  right
                  v-if="admin"
                  style="margin-top: -15px"
                  @click="
                    editText(
                      'text',
                      `manual${mazatlan ? '_mzt' : ''}_home_page_banner_4`
                    )
                  "
                >
                  <v-icon color="error">mdi-pencil</v-icon>
                </v-btn>
                <img src="@/assets/4.svg" style="position: absolute" />
                <div class="mt-3 card__text">
                  {{
                    `manual${mazatlan ? "_mzt" : ""}_home_page_banner_4`
                      | localize
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-row>
      <v-row
        class="mt-5"
        no-gutters
        style="position: relative"
        justify="center"
      >
        <v-btn
          icon
          absolute
          right
          v-if="admin"
          style="margin-top: -26px; right: 0px"
          @click="
            editText(
              'description',
              `manual${mazatlan ? '_mzt' : ''}_home_page_text_after_banner`
            )
          "
        >
          <v-icon color="error">mdi-pencil</v-icon>
        </v-btn>
        <div
          class="bottom-desc"
          style="white-space: pre-wrap;"
          :class="{'bottom-desc-mobile' : $vuetify.breakpoint.smAndDown}"
        >
          {{
            `manual${mazatlan ? "_mzt" : ""}_home_page_text_after_banner`
              | localize
          }}
        </div>
      </v-row>
    </v-container>
    <edit-cards-modal
      v-if="editCards"
      @close_modal="closeModal"
      :rules="rules"
      :code="code"
    />
    <edit-gallery
      v-if="galleryDialogVisible"
      @close="galleryDialogVisible = false"
      :visible="galleryDialogVisible"
    />
  </div>
</template>

<script>
import editCardsModal from "./Editors/editCards.vue";
import editGallery from "./Editors/editGallery.vue";
import { mapGetters } from "vuex";
import cityMixin from "@/mixins/cityMixin";

export default {
  components: {
    editCardsModal,
    editGallery,
  },
  mixins: [cityMixin],
  data: () => ({
    editCards: false,
    galleryDialogVisible: false,
    rules: null,
    code: null,
    admin: false,
    currentID: 0,
    locale: "",
    images: [
      require("@/assets/cathedral_home.png"),
      require("@/assets/zoo.jpeg"),
      require("@/assets/water.png"),
      require("@/assets/acuriano_michin_children.png"),
      require("@/assets/museum.jpeg"),
      require("@/assets/kidzania.jpeg"), 
      require("@/assets/acuario_michin.jpg"),
    ],
  }),
  mounted() {
    if (this.mazatlan) {
      this.images = [
        require("@/assets/mazatlan_assets/home/mazatlan_home_banner_1.jpg"),
        require("@/assets/mazatlan_assets/home/mazatlan_home_banner_2.jpeg"),
        require("@/assets/mazatlan_assets/home/mazatlan_home_banner_3.png"),
        require("@/assets/mazatlan_assets/home/mazatlan_home_banner_4.jpeg"),
        // require("@/assets/mazatlan_assets/home/mazatlan_home_banner_5.jpeg"),
      ];
    }
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    } else {
      this.admin = false;
    }
    if (this.loggedUser) {
      this.locale = this.loggedUser.locale;
    } else {
      this.locale = this.user.locale;
    }
    setInterval(() => {
      this.toggleImage();
    }, 3000);
  },
  methods: {
    editText(rules, code) {
      this.rules = rules;
      this.code = code;
      this.editCards = true;
    },
    closeModal() {
      this.editCards = false;
      this.rules = null;
    },
    toggleImage() {
      if (this.currentID < this.images.length - 1) {
        this.currentID += 1;
      } else {
        this.currentID = 0;
      }
    },
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
        } else {
          this.admin = false;
        }
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.locale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.locale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped lang="scss">
.img-gradient {
  position: relative;
  width: 100%;
  height: 100%;
}
.title__inner {
  font-weight: 800;
  font-size: 50px;
  line-height: 55px;
  z-index: 2;
}

.subtitle__inner {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  z-index: 2;
}
.main img {
  width: 100%;
  display: flex;
  position: absolute;
  -webkit-transition: all 3s ease;
  transition: all 3s ease;
  border-radius: 30px;
}

.img-container {
  background-size: cover;
  box-sizing: border-box;
  width: 100%;
  height: 450px;
  border-radius: 30px;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.card__steps {
  z-index: 1;
  border-left: 3px solid #e62076;
  box-shadow: 0px 2.8px 4.9px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px !important;
  height: 135px;
  width: inherit;
}

.card__text {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}
.main::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient(
    90deg,
    #ffffffdd 0.23%,
    rgba(255, 255, 255, 0) 60%
  );
}

.img-gradient img {
  display: block;
  border-radius: 30px;
  width: 100%;
}

.slide-fade-enter-active {
  opacity: 1;
  z-index: 1;
}

.slide-fade-leave-active {
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.bottom-desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 70%;
  &-mobile {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .card__steps {
    height: 180px;
  }
  .title__inner {
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
  }

  .subtitle__inner {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 376px) {
  .card__steps {
    height: 200px !important;
  }
}
@media (max-width: 960px) {
}
</style>