<template>
  <div>
    <main-info />
    <reviews />
    <hoozie-rewards-site-description class="my-6" v-if="mazatlan"/>
    <attractions-list />
    <pulic-transport v-if="!mazatlan"/>
    <supported-by v-if="!mazatlan"/>
  </div>
</template>

<script>
import MainInfo from "./MainInfo.vue";
import AttractionsList from "./AttractionsList.vue";
import SupportedBy from "./SupportedBy.vue";
import Reviews from "./Reviews.vue";
import pulicTransport from "./publicTransportComponent.vue";
import hoozieRewardsSiteDescription from "@/components/UI/Banners/hoozieRewardsSiteDescription";
import mobile from '@/mixins/mobileMixin'
import cityMixin from '@/mixins/cityMixin'

export default {
  components: {
    MainInfo,
    AttractionsList,
    SupportedBy,
    Reviews,
    pulicTransport,
    hoozieRewardsSiteDescription,
  },
  mixins: [mobile, cityMixin],
};
</script>