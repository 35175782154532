<template>
  <div>
    <v-container class="mt-8">
      <v-row
        no-gutters
        class="mb-2 text-center"
        justify="center"
        style="
          font-weight: 600;
          font-size: 24px;
          line-height: 130.4%;
          position: relative;
        "
      >
        <v-col cols="9" class="pa-0"
          >{{ `manual${mazatlan ? '_mzt' : ''}_reviews_title` | localize
          }}<v-btn
            icon
            class="ml-5"
            v-if="admin"
            @click="editText('text', `manual${mazatlan ? '_mzt' : ''}_reviews_title`)"
          >
            <v-icon color="error">mdi-pencil</v-icon>
          </v-btn></v-col
        >
        <v-btn
          v-if="admin"
          fab
          color="#E62076"
          right
          dark
          small
          center
          absolute
          class="mb-1"
          @click="openModal()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-row>
      <div
        v-if="reviews.length === 0"
        style="min-height: 200px"
        class="d-flex align-center justify-center"
      >
       {{ 'home_no_reviews_label' | localize }}
      </div>
      <swiper
        v-if="reviews.length > 0"
        class="swiper d-none d-lg-block"
        :style="{
          '--swiper-navigation-color': '#000000',
        }"
        :options="swiperOption"
      >
        <swiper-slide v-for="review in reviews" :key="review.id">
          <v-card class="reviews_card mt-5 px-4 py-5 mx-10">
            <v-rating
              color="#FFC909"
              hover
              :length="5"
              readonly
              :size="13"
              :value="parseInt(review.stars)"
              dense
            >
            </v-rating>
            <div class="text_review mt-2" v-if="loggedUser">
              {{
                loggedUser.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="text_review mt-2" v-else>
              {{
                user.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="author_review mt-4" v-if="loggedUser">
              {{
                loggedUser.locale == "en" ? review.title_en : review.title_es
              }}
            </div>
            <div class="author_review mt-4" v-else>
              {{ user.locale == "en" ? review.title_en : review.title_es }}
            </div>
          </v-card>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <swiper
        v-if="reviews.length > 0"
        class="swiper d-none d-md-block d-lg-none"
        :style="{
          '--swiper-navigation-color': '#000000',
        }"
        :options="swiperMediumOption"
      >
        <swiper-slide v-for="review in reviews" :key="review.id">
          <v-card class="reviews_card mt-5 px-4 py-5 mx-10">
            <v-rating
              color="#FFC909"
              hover
              length="5"
              readonly
              size="13"
              :value="parseInt(review.stars)"
              dense
            >
            </v-rating>
            <div class="text_review mt-2" v-if="loggedUser">
              {{
                loggedUser.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="text_review mt-2" v-else>
              {{
                user.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="author_review mt-4" v-if="loggedUser">
              {{
                loggedUser.locale == "en" ? review.title_en : review.title_es
              }}
            </div>
            <div class="author_review mt-4" v-else>
              {{ user.locale == "en" ? review.title_en : review.title_es }}
            </div>
          </v-card>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <swiper
        v-if="reviews.length > 0"
        class="swiper d-block d-md-none"
        :style="{
          '--swiper-navigation-color': '#000000',
        }"
        :options="swiperMobileOption"
      >
        <swiper-slide v-for="review in reviews" :key="review.id">
          <v-card class="reviews_card mt-5 px-4 py-5 mx-10">
            <v-rating
              color="#FFC909"
              hover
              length="5"
              readonly
              size="13"
              :value="parseInt(review.stars)"
              dense
            >
            </v-rating>
            <div class="text_review mt-2" v-if="loggedUser">
              {{
                loggedUser.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="text_review mt-2" v-else>
              {{
                user.locale == "en"
                  ? review.description_en
                  : review.description_es
              }}
            </div>
            <div class="author_review mt-4" v-if="loggedUser">
              {{
                loggedUser.locale == "en" ? review.title_en : review.title_es
              }}
            </div>
            <div class="author_review mt-4" v-else>
              {{ user.locale == "en" ? review.title_en : review.title_es }}
            </div>
          </v-card>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </v-container>
    <edit-modal
      v-if="editModal"
      @close_modal="closeModal"
      @changed="changedReview"
    />
    <edit-cards-modal
      v-if="editCards"
      @close_modal="closeTextModal()"
      :rules="rules"
      :code="code"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapGetters } from "vuex";
import editModal from "./Reviews/EditReviewModal.vue";
import reviewsService from "@/requests/Reviews/ReviewsService.js";
import editCardsModal from "./Editors/editCards.vue";
import cityMixin from '@/mixins/cityMixin'

export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  mixins: [cityMixin],
  components: {
    Swiper,
    SwiperSlide,
    editModal,
    editCardsModal,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 3,
        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperMobileOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperMediumOption: {
        slidesPerView: 2,
        spaceBetween: 0,
        slidesPerGroup: 2,
        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      admin: false,
      editModal: false,
      reviews: [],
      rules: null,
      code: null,
      editCards: false,
    };
  },
  mounted() {
    this.getReviews();
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    } else {
      this.admin = false;
    }
  },
  methods: {
    editText(rules, code) {
      this.rules = rules;
      this.code = code;
      this.editCards = true;
    },
    closeTextModal() {
      this.editCards = false;
      this.rules = null;
    },
    changedReview() {
      this.getReviews();
    },
    closeModal() {
      this.editModal = false;
    },
    openModal() {
      this.editModal = true;
    },
    async getReviews() {
      await reviewsService.getReviewsList().then((response) => {
        this.reviews = response.result.sort((a, b) => a.position - b.position);
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
        } else {
          this.admin = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.swiper-wrapper {
  align-items: center !important;
  height: 100%; /* add this will all relevant prefixes */
}
.swiper {
  height: 225px !important;
}
.swiper-slide {
  text-align: center;
  align-items: center;
  height: auto;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 30px;
}
.reviews_card {
  background: rgba(230, 32, 118, 0.03);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.text_review {
  font-weight: 500;
  font-size: 12px;
  line-height: 130.4%;
}

.author_review {
  font-weight: 600;
  font-size: 12px;
  line-height: 130.4%;
}
</style>