<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <div :style="!$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''">
        <v-row
          align="center"
          :justify="$vuetify.breakpoint.lg ? 'start' : 'center'"
          :style="$vuetify.breakpoint.smAndDown ? 'display:block;' : ''"
        >
          <div class="mobile-banner" v-if="$vuetify.breakpoint.smAndDown">
            <v-row no-gutters align="center" justify="start">
              <img src="@/assets/hoozieLogo.svg" style="z-index: 10" />
              <div
                class="descriptionText"
                style="text-align: center; margin-left: 15px"
              >
                <v-col cols="12" class="px-0" style="text-align: left">
                  <span
                    style="
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 22px;
                      color: #444444;
                    "
                    >{{ "hoozie_rewards_business_banner1" | localize }}&nbsp;
                    <span
                      style="color: #e62076; cursor: pointer"
                      @click="$router.push('/hoozie_rewards')"
                      >{{
                        "hoozie_rewards_business_banner2" | localize
                      }}&nbsp;</span
                    >
                    {{ "hoozie_rewards_business_banner3" | localize }}</span
                  >
                  <v-row
                    no-gutters
                    align="center"
                    style="margin-top: 9px"
                    justify="center"
                  >
                    <img
                      @click="navigateTo('https://www.apple.com/app-store/')"
                      style="cursor: pointer"
                      src="@/assets/downloadAppleStoreHoozieReward.svg"
                      width="62px"
                      height="20px"
                    />
                    <img
                      @click="navigateTo('https://play.google.com/store/')"
                      style="cursor: pointer"
                      class="appleIcon"
                      src="@/assets/downloadPlayMarketHoozieReward.svg"
                      width="62px"
                      height="20px"
                    />
                  </v-row>
                </v-col>
              </div>
            </v-row>
          </div>
          <v-row
            align="center"
            justify="center"
            v-else
            style="position: relative"
          >
            <img src="@/assets/hoozieLogo.svg" style="z-index: 10" />
            <v-row
              no-gutters
              justify="center"
              style="position: absolute; z-index: 10"
            >
              <v-col cols="7" class="pa-0 mr-3">
                <span
                  style="
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 40px;
                    color: #444444; 
                    text-align: center; 
                  "
                  >{{ "hoozie_rewards_business_banner1" | localize }}&nbsp;
                  <u
                    style="color: #e62076; cursor: pointer"
                    @click="
                      $route.name == 'hoozie_rewards'
                        ? $vuetify.goTo(0,0)
                        : $router.push('/hoozie_rewards')
                    "
                  >
                    {{ "hoozie_rewards_business_banner2" | localize }}</u
                  >&nbsp;{{
                    "hoozie_rewards_business_banner3" | localize
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row justify="end" class="mr-13">
              <v-col cols="3" style="z-index: 50">
                <img
                  @click="navigateTo('https://www.apple.com/app-store/')"
                  style="cursor: pointer"
                  src="@/assets/downloadAppleStoreHoozieReward.svg"
                />
                <br />
                <img
                  @click="navigateTo('https://play.google.com/store/')"
                  style="cursor: pointer"
                  src="@/assets/downloadPlayMarketHoozieReward.svg"
                />
              </v-col>
            </v-row>
            <img
              src="@/assets/hoozieRewardsDescriptionField.png"
              style="
                position: absolute;
                box-shadow: 5px 0px 10px 1px silver;
                border-radius: 30px;
              "
            />
          </v-row>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    navigateTo(link) {
      window.location.href = link;
    },
    scrollOnTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.mobile-banner {
  background-image: url("../../../assets/hoozieRewardsDescriptionFieldMobile.png");
  background-size: contain;
  box-shadow: 5px 0px 10px 1px silver;
  border-radius: 30px;
}
@media only screen and (max-width: 350px) {
  .descriptionText {
    width: 130px;
  }
  .appleIcon {
    margin-left: 5px;
  }
  .mobile-banner {
    width: 300px;
    background-size: 300px 200px;
  }
}
@media only screen and (max-width: 424px) and (min-width: 350px) {
  .descriptionText {
    width: 170px;
  }
  .appleIcon {
    margin-left: 21px;
  }
  .mobile-banner {
    width: 340px;
    background-size: 350px 150px;
  }
}
@media only screen and (max-width: 650px) and (min-width: 425px) {
  .descriptionText {
    width: 228px;
  }
  .appleIcon {
    margin-left: 21px;
  }
  .mobile-banner {
    width: 405px;
    background-size: cover;
  }
}
</style>