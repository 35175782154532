<template>
  <v-dialog v-model="visibility" width="800px">
    <v-card>
      <v-card-title>
        <v-row no-gutters justify="space-between">
          <div>Edit Gallery</div>
          <v-btn
            color="#e62076"
            dark
            fab
            @click="$refs.uploadNew.click()"
            small
          >
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <draggable
            v-model="images"
            style="display: flex; flex-wrap: wrap"
            v-if="images.length > 0"
          >
            <v-col class="col-3" v-for="(image, index) in images" :key="index">
              <div style="position: relative">
                <div class="delete-icon" @click="imageIndexToDelete = index; confirmDeleteVisible = true">x</div>
                <img
                  class="gallery-img"
                  :src="image.url"
                  @click="
                    imageToChange = image;
                    $refs.upload.click();
                  "
                />
              </div>
            </v-col>
          </draggable>
          <!-- <v-col class="col-3">
            <div
              @click="$refs.uploadNew.click()"
              class="gallery-img gallery-img-empty align-center"
            >
              <div>
                <v-icon class="col-12 pa-0" color="rgb(170, 170, 170)" large
                  >mdi-plus</v-icon
                >
                <div>Upload image</div>
              </div>
            </div>
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn class="mb-4" text color="#7A7A7A" @click="visibility = false">
            {{ "button_cancel" | localize }}
          </v-btn>
          <v-btn dark color="#e62076" @click="save()"> {{ 'button_save' | localize }} </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <input type="file" @change="uploaded" ref="upload" style="display: none" />
    <input
      type="file"
      @change="uploadedNew"
      ref="uploadNew"
      style="display: none"
    />
    <confirm-delete-image
      :visible="confirmDeleteVisible"
      v-if="confirmDeleteVisible"
      @close="confirmDeleteVisible = false"
      @onConfirm="deleteImage"
    />
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import confirmDeleteImage from "./confirmDeleteImage.vue";

export default {
  components: {
    draggable,
    confirmDeleteImage,
  },
  props: {
    visible: {
      require: true,
    },
  },
  data: () => ({
    imageToChange: {},
    confirmDeleteVisible: false,
    imageIndexToDelete: -1,
    images: [
      {
        id: "1",
        url: "https://pass.hoozaround.com/img/guadalajara-logo-for-email.ab9cf709.png",
      },
      {
        id: "2",
        url: "https://pass.hoozaround.com/img/guadalajara-logo-for-email.ab9cf709.png",
      },
      {
        id: "3",
        url: "https://pass.hoozaround.com/img/background-for-email.be7e5dd0.png",
      },
      {
        id: "4",
        url: "https://pass.hoozaround.com/img/guadalajara-logo-for-email.ab9cf709.png",
      },
      {
        id: "5",
        url: "https://pass.hoozaround.com/img/guadalajara-logo-for-email.ab9cf709.png",
      },
    ],
  }),
  methods: {
    save() {
      this.visibility = false;
    },
    deleteImage() {
      if (this.imageIndexToDelete != -1) {
        this.images.splice(this.imageIndexToDelete, 1);
        this.imageIndexToDelete = -1;
        this.confirmDeleteVisible = false
      }
      this.confirmDeleteVisible = false
    },
    uploaded(e) {
      let file = e.srcElement.files[0];
      var reader = new FileReader();
      let that = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        that.imageToChange.url = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    uploadedNew(e) {
      let file = e.srcElement.files[0];
      var reader = new FileReader();
      let that = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        that.images.push({ url: reader.result });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped lang="scss">
.gallery-img {
  width: 100%;
  height: 100px;
  min-height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  border: 1px rgb(238, 238, 238) solid;
  &-empty {
    background-color: rgb(238, 238, 238);
    align-content: center;
    justify-content: center;
    display: flex;
    border: 3px solid rgb(217, 217, 217);
    color: rgb(178, 178, 178);
    font-weight: bold;
  }
}
.delete-icon {
  border-radius: 50%;
  border: 1px solid rgb(154, 154, 154);
  width: 17px;
  height: 17px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: -3px;
  top: -3px;
  cursor: pointer;
  background-color: #fff;
  color: black;
  font-size: 10px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
</style>