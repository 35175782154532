var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"main",staticStyle:{"position":"relative","width":"100%","height":"500px"}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('img',{key:_vm.currentID,staticStyle:{"position":"absolute","width":"100%","height":"100%","display":"block","object-fit":"cover"},attrs:{"src":_vm.images[_vm.currentID]}})]),_c('v-row',{staticStyle:{"position":"relative","z-index":"2"},attrs:{"no-gutters":""}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"20px"},attrs:{"absolute":"","small":"","right":"","fab":"","color":"#fff","dark":""},on:{"click":function($event){return _vm.editText('title', '')}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('div',{staticClass:"ml-0 ml-sm-10 mt-sm-15 mt-7 title__inner"},[_c('v-col',{staticClass:"col-sm-8 col-12"},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_title")))+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"ml-0 ml-sm-10 mt-sm-4 mt-1 subtitle__inner"},[_c('v-col',{staticClass:"col-sm-7 col-12 py-sm-auto py-0"},[_vm._v(_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_text"))))])],1)])],1)]),_c('div',{staticClass:"mx-md-6 mx-0",staticStyle:{"margin-top":"-35px","width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"col-lg-3 col-6"},[_c('v-card',{staticClass:"pa-5 pb-9 card__steps",attrs:{"elevation":"0"}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"-15px"},attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.editText(
                    'text',
                    ("manual" + (_vm.mazatlan ? '_mzt' : '') + "_home_page_banner_1")
                  )}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/1.svg")}}),_c('div',{staticClass:"mt-3 card__text"},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_1")))+" ")])],1)],1),_c('v-col',{staticClass:"col-lg-3 col-6"},[_c('v-card',{staticClass:"pa-5 pb-9 card__steps",attrs:{"elevation":"0"}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"-15px"},attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.editText(
                    'text',
                    ("manual" + (_vm.mazatlan ? '_mzt' : '') + "_home_page_banner_2")
                  )}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/2.svg")}}),_c('div',{staticClass:"mt-3 card__text"},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_2")))+" ")])],1)],1),_c('v-col',{staticClass:"col-lg-3 col-6"},[_c('v-card',{staticClass:"pa-5 pb-9 card__steps",attrs:{"elevation":"0"}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"-15px"},attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.editText(
                    'text',
                    ("manual" + (_vm.mazatlan ? '_mzt' : '') + "_home_page_banner_3")
                  )}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/3.svg")}}),_c('div',{staticClass:"mt-3 card__text"},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_3")))+" ")])],1)],1),_c('v-col',{staticClass:"col-lg-3 col-6"},[_c('v-card',{staticClass:"pa-5 pb-8 card__steps",attrs:{"elevation":"0"}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"-15px"},attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.editText(
                    'text',
                    ("manual" + (_vm.mazatlan ? '_mzt' : '') + "_home_page_banner_4")
                  )}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/4.svg")}}),_c('div',{staticClass:"mt-3 card__text"},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_banner_4")))+" ")])],1)],1)],1)],1)]),_c('v-row',{staticClass:"mt-5",staticStyle:{"position":"relative"},attrs:{"no-gutters":"","justify":"center"}},[(_vm.admin)?_c('v-btn',{staticStyle:{"margin-top":"-26px","right":"0px"},attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.editText(
            'description',
            ("manual" + (_vm.mazatlan ? '_mzt' : '') + "_home_page_text_after_banner")
          )}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('div',{staticClass:"bottom-desc",class:{'bottom-desc-mobile' : _vm.$vuetify.breakpoint.smAndDown},staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm._f("localize")(("manual" + (_vm.mazatlan ? "_mzt" : "") + "_home_page_text_after_banner")))+" ")])],1)],1),(_vm.editCards)?_c('edit-cards-modal',{attrs:{"rules":_vm.rules,"code":_vm.code},on:{"close_modal":_vm.closeModal}}):_vm._e(),(_vm.galleryDialogVisible)?_c('edit-gallery',{attrs:{"visible":_vm.galleryDialogVisible},on:{"close":function($event){_vm.galleryDialogVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }